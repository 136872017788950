import './form.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import apiConfig from '../../services/api';
import { useTelegram } from '../../hooks/useTelegram';

function ResumeForm() {
  const { t } = useTranslation();
  const [resumeData, setResumeData] = useState({
    name: '',
    region: '',
    sphere: '',
    profession: '',
    exp: '',
    salary: '',
    currency: '',
  });

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLanguageDegrees, setSelectedLanguageDegrees] = useState({
    English: '',
    Russian: '',
  });
  const [regions, setRegions] = useState([]);
  const [spheres, setSpheres] = useState([]);
  const [prof, setProf] = useState([]);
  const { tg } = useTelegram();

  const onSendData = useCallback(() => {
    tg.sendData(JSON.stringify(resumeData));
  }, [resumeData, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData, tg]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Create a resume',
    });
  }, [tg]);

  useEffect(() => {
    if (
      resumeData.name &&
      resumeData.region &&
      resumeData.sphere &&
      resumeData.profession &&
      resumeData.exp &&
      resumeData.salary &&
      resumeData.currency
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [resumeData, tg]);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const { data } = await apiConfig.request.get('/regions');

        setRegions(data.data.regions);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchSpheres = async () => {
      try {
        const { data } = await apiConfig.request.get('/spheres');

        setSpheres(data.data.spheres);
      } catch (error) {
        console.error('Error fetching spheres:', error);
      }
    };

    fetchSpheres();
  }, []);

  useEffect(() => {
    const fetchSelectedSpheres = async () => {
      try {
        if (resumeData.sphere) {
          const { data } = await apiConfig.request.get(
            `/spheres/${resumeData.sphere}/professions`
          );

          setProf(data.data.professions);
        }
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    };

    fetchSelectedSpheres();
  }, [resumeData]);

  const [options, setOptions] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const fetchInterests = async () => {
    try {
      setLoading(true);
      const perPage = 10;
      const url =
        value.length > 0
          ? `${
              process.env.REACT_APP_BASE_URL
            }/technologies?page_number=${currentPage}&per_page=${perPage}&name=${encodeURIComponent(
              value
            )}`
          : `${process.env.REACT_APP_BASE_URL}/technologies?page_number=${currentPage}&per_page=${perPage}`;

      const { data } = await apiConfig.request.get(url);
      const fetchedOptions = data?.data?.technologies || [];

      const mappedOptions = fetchedOptions.map((item) => ({
        value: item._id,
        label: item.name,
      }));

      return mappedOptions;
    } catch (error) {
      console.error('Error fetching interests:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newValue) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  const loadMoreOptions = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const containerRef = useRef();

  const handleInterestChange = (selectedOptions) => {
    setSelectedInterest(selectedOptions);
  };

  useEffect(() => {
    const loadOptions = async () => {
      const fetchedOptions = await fetchInterests();
      if (currentPage === 1) {
        setOptions(fetchedOptions);
      } else {
        setOptions((prevOptions) => [...prevOptions, ...fetchedOptions]);
      }
    };

    loadOptions();
  }, [currentPage, value]);

  function handleLanguageChange(event) {
    const language = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedLanguages((prevSelectedLanguages) => [
        ...prevSelectedLanguages,
        language,
      ]);
    } else {
      setSelectedLanguages((prevSelectedLanguages) =>
        prevSelectedLanguages.filter((lang) => lang !== language)
      );
    }
  }

  function handleLanguageDegreeChange(event, language) {
    const degree = event.target.value;

    setSelectedLanguageDegrees((prevDegrees) => ({
      ...prevDegrees,
      [language]: degree,
    }));
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setResumeData({
      ...resumeData,
      [name]: value,
    });
  };

  return (
    <div className="container">
      <div className="resume_title">
        <h1>Here Jobs Resume</h1>
      </div>
      <label htmlFor="nameInput">Name:</label>
      <input
        type="text"
        id="nameInput"
        placeholder="Name"
        name="name"
        value={resumeData.name}
        onChange={handleInputChange}
      />
      <br />
      <label htmlFor="regionSelect">Choose a region:</label>
      <Form.Select
        id="regionSelect"
        aria-label="Region select"
        name="region"
        value={resumeData.region}
        onChange={handleInputChange}
      >
        <option className="optionPlaceholder" value="" disabled selected hidden>
          Choose Region
        </option>
        {regions.length > 0 ? (
          regions.map((region) => (
            <option key={region._id} value={region._id}>
              {region?.translations?.uz?.name}
            </option>
          ))
        ) : (
          <option value="default" disabled>
            No regions available
          </option>
        )}
      </Form.Select>
      <label htmlFor="sphereSelect">Choose a sphere:</label>
      <Form.Select
        id="sphereSelect"
        aria-label="Sphere select"
        name="sphere"
        value={resumeData.sphere}
        onChange={handleInputChange}
      >
        <option className="optionPlaceholder" value="" disabled selected hidden>
          Choose Sphere
        </option>
        {spheres?.length > 0 ? (
          spheres.map((sphere) => (
            <option key={sphere._id} value={sphere._id}>
              {sphere?.translations?.uz?.name}
            </option>
          ))
        ) : (
          <option value="default" disabled>
            No spheres available
          </option>
        )}
      </Form.Select>
      {/* Professions */}
      <label htmlFor="professionSelect">Choose a profession:</label>
      <Form.Select
        id="professionSelect"
        aria-label="Profession selet"
        name="profession"
        value={resumeData.profession}
        onChange={handleInputChange}
      >
        <option className="optionPlaceholder" value="" disabled selected hidden>
          Choose Profession
        </option>
        {prof?.length > 0 ? (
          prof.map((profession) => (
            <option key={profession._id} value={profession._id}>
              {profession?.translations?.uz?.name}
            </option>
          ))
        ) : (
          <option value="default" disabled>
            No professions available
          </option>
        )}
      </Form.Select>
      <div>
        <label htmlFor="technologiesSelect">Choose technologies:</label>
        <Select
          isMulti
          id="technologiesSelect"
          cacheOptions
          defaultOptions
          options={options}
          value={selectedInterest}
          onChange={handleInterestChange}
          onInputChange={handlePageChange}
          onMenuScrollToBottom={loadMoreOptions}
        />
        {/* {loading && <p>Loading...</p>} */}
        <div ref={containerRef} style={{ height: '10px' }}></div>
      </div>
      <br />
      {/* Languages */}
      <label htmlFor="languageSelect">Languages:</label>
      <br />
      <input
        type="checkbox"
        id="englishCheckbox"
        value="English"
        onChange={handleLanguageChange}
        checked={selectedLanguages.includes('English')}
      />
      <label htmlFor="englishCheckbox">English</label>
      {/* Render English language dropdown if its checkbox is checked */}
      {selectedLanguages.includes('English') && (
        <>
          <Form.Select
            id="englishSelect"
            aria-label="English Select"
            onChange={(e) => handleLanguageDegreeChange(e, 'English')}
          >
            <option
              className="optionPlaceholder"
              value="default"
              disable="true"
              hidden
            >
              Choose Degree
            </option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
            {/* Add more options as needed */}
          </Form.Select>
        </>
      )}
      <br />
      <input
        type="checkbox"
        id="russianCheckbox"
        value="Russian"
        onChange={handleLanguageChange}
        checked={selectedLanguages.includes('Russian')}
      />
      <label htmlFor="russianCheckbox">Russian</label>
      {/* Render Russian language dropdown if its checkbox is checked */}
      {selectedLanguages.includes('Russian') && (
        <>
          <Form.Select
            id="russianSelect"
            aria-label="Russian Select"
            onChange={(e) => handleLanguageDegreeChange(e, 'Russian')}
          >
            <option
              className="optionPlaceholder"
              value="default"
              disable="true"
              hidden
            >
              Choose Degree
            </option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
            {/* Add more options as needed */}
          </Form.Select>
        </>
      )}
      <br />
      <br />
      {/* Experience */}
      <label htmlFor="expInput">Experience (years):</label>
      <input
        type="number"
        id="expInput"
        name="exp"
        value={resumeData.exp}
        onChange={handleInputChange}
      />{' '}
      <br />
      {/* Salary */}
      <label htmlFor="salaryInput">Salary:</label>
      <input
        type="number"
        id="salaryInput"
        name="salary"
        value={resumeData.salary}
        onChange={handleInputChange}
      />
      <Form.Select
        id="currencySelect"
        aria-label="Default select example"
        name="currency"
        value={resumeData.currency}
        onChange={handleInputChange}
      >
        <option
          className="optionPlaceholder"
          value="default"
          disable="true"
          hidden
        >
          Choose Currency
        </option>
        <option value="Sum">Sum</option>
        <option value="Usd">USD</option>
      </Form.Select>
    </div>
  );
}

export default ResumeForm;
