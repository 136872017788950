import React, { useEffect } from 'react';
import './App.css';
import Form from './component/Form/Form';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTelegram } from './hooks/useTelegram';

export default function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  });

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Form />} />
        </Routes>
      </Router>
    </div>
  );
}
