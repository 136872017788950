import axios from "axios";
import { appConfig } from "../config";

const request = axios.create({
  baseURL: appConfig.API_ROOT,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json; charset=utf-8",
    "ngrok-skip-browser-warning": "true",
  },
});

request.interceptors.request.use(
  (config) => {
    // You can modify the request configuration here if needed
    // For example, adding authentication tokens, logging, etc.
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // You can modify the response data here if needed
    // For example, extracting certain data or handling specific status codes
    return response;
  },
  (error) => {

    return Promise.reject(error);
  }
);

const apiConfig = { request };

export default apiConfig;
